







import { Component, Vue } from 'vue-property-decorator'
import { Logs } from '@/api'

@Component({
  components: {
    LogsTable: () => import('@/components/Logs/LogsTable.vue'),
  }
})
export default class NicknameChanges extends Vue {
  data = [] as any[]

 get tableColumns() {
   return [
     { key: 'accountId' },
     { key: 'characterId', label: 'Character Name' },
     { key: 'oldName', label: 'Old nickname' },
     { key: 'newName', label: 'New nickname' },
     { key: 'createdAt', label: 'Date' },
   ]
 }

  async mounted() {
    const { result } = this.$route.params.characterId
     ? await Logs.getNicknameChangesById(this.$route.params.characterId)
     : await Logs.getNicknameChanges()

    this.data = result
     .reverse()
     .map(e => ({
       ...e,
       createdAt: new Date(e.createdAt).toUTCString()
     }))
  }
}
